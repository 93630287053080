/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, Image, SeparateLine, Fullmap, FullmapWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu pb--08 pt--08" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box fs--22" content={"<a href=\"https://michaelasynacovafotografie.cz\">Michaela Synáčová Fotografie </a>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

              </MenuWrapBox>

              <MenuWrapBox style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz"} use={"url"} href={"https://michaelasynacovafotografie.cz"} target={""} content={"<span style=\"font-weight: 400; color: rgb(0, 0, 0);\">home</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" use={"page"} href={"/foceni"} target={""} content={"<span style=\"caret-color: rgb(2, 0, 0); color: rgb(2, 0, 0); font-weight: 400;\">CENÍKY</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/info/"} use={"url"} href={"https://michaelasynacovafotografie.cz/info/"} target={""} content={"<span style=\"font-weight: 400; color: rgb(4, 0, 0);\">INFO</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" url={"https://michaelasynacovafotografie.cz/kontakt/"} use={"url"} href={"https://michaelasynacovafotografie.cz/kontakt/"} target={""} content={"<div><span style=\"font-weight: normal; color: rgb(0, 0, 0);\">KONTAKT</span><br></div>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--full pb--60 pt--60" name={"j86e97qtqn"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} parallax={false} fullscreen={true} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Michaela Synáčová Fotografie&nbsp;<br>"}>
              </Subtitle>

              <Title className="title-box fs--72 title-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">NEWBORN \nA RODINNÁ FOTOGRAFKA</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"idhcolr927p"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":654}} content={"<span style=\"font-style: italic;\">„Je jen jeden způsob jak zastavit čas; fotografie.“ —  Michal Hazuka&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"3by9gqab09d"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/9faea44b34f14316864ce78189148a53_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/acb82a95f0ef4d609c8a9308397b70e8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/acb82a95f0ef4d609c8a9308397b70e8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/acb82a95f0ef4d609c8a9308397b70e8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/acb82a95f0ef4d609c8a9308397b70e8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/acb82a95f0ef4d609c8a9308397b70e8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/557343e711bf4bbaaa436101e7f2731d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/557343e711bf4bbaaa436101e7f2731d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/557343e711bf4bbaaa436101e7f2731d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/557343e711bf4bbaaa436101e7f2731d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/557343e711bf4bbaaa436101e7f2731d_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/cd3eca5999764ff7946dde23766b102f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/cd3eca5999764ff7946dde23766b102f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/cd3eca5999764ff7946dde23766b102f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/cd3eca5999764ff7946dde23766b102f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/cd3eca5999764ff7946dde23766b102f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/cd3eca5999764ff7946dde23766b102f_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"u2l4p1s4q2"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/770faabe082e4a55bffe8223564eb06c_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/05531066360f449099723e86377de811_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/05531066360f449099723e86377de811_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/05531066360f449099723e86377de811_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/05531066360f449099723e86377de811_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/05531066360f449099723e86377de811_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/2d1e639a11c7407abb44b292fc46d26d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/2d1e639a11c7407abb44b292fc46d26d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/2d1e639a11c7407abb44b292fc46d26d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/2d1e639a11c7407abb44b292fc46d26d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/2d1e639a11c7407abb44b292fc46d26d_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/c02deac652594795b725798be13ded60_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/c02deac652594795b725798be13ded60_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/c02deac652594795b725798be13ded60_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/c02deac652594795b725798be13ded60_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/c02deac652594795b725798be13ded60_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/c02deac652594795b725798be13ded60_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"iokt6l8ycgd"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/f4701ad3aab44b15b851409f0eeac01f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/f4701ad3aab44b15b851409f0eeac01f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/f4701ad3aab44b15b851409f0eeac01f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/f4701ad3aab44b15b851409f0eeac01f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/f4701ad3aab44b15b851409f0eeac01f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/f4701ad3aab44b15b851409f0eeac01f_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/edc8d3a63321413fba9d97e9d2f79b07_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/edc8d3a63321413fba9d97e9d2f79b07_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/edc8d3a63321413fba9d97e9d2f79b07_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/edc8d3a63321413fba9d97e9d2f79b07_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/edc8d3a63321413fba9d97e9d2f79b07_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13101/1f65f149068e4d599b0f9230710b5ef3_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/1f65f149068e4d599b0f9230710b5ef3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/1f65f149068e4d599b0f9230710b5ef3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/1f65f149068e4d599b0f9230710b5ef3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/1f65f149068e4d599b0f9230710b5ef3_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/13101/916c75f660f24075ae973bb5eba1d2f7_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13101/916c75f660f24075ae973bb5eba1d2f7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13101/916c75f660f24075ae973bb5eba1d2f7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13101/916c75f660f24075ae973bb5eba1d2f7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13101/916c75f660f24075ae973bb5eba1d2f7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13101/916c75f660f24075ae973bb5eba1d2f7_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"4jqrkvxw297"} layout={"l6"}>
        </SeparateLine>


        <Fullmap className="--style2" name={"mapa"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--50 pt--50" style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"} parallax={false} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--20 subtitle-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">Michaela Synáčová Fotografie<br></span><br>"}>
              </Subtitle>

              <Title className="title-box fs--26 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">NEWBORN A RODINNÁ FOTOGRAFKA</span>"}>
              </Title>

              <Text className="text-box fs--14 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">5.května 653, Blovice</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert" content={"<span style=\"color: rgb(0, 0, 0);\">+420 739 449 891</span>"}>
              </Text>

              <Text className="text-box fs--16 w--500 text-box--invert mt--0" content={"<span style=\"font-style: italic; color: rgb(0, 0, 0);\">michaela.synacova.fotografie@gmail.com</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn1 btn-box--sbtn2 fs--16" href={"https://www.facebook.com/michaelasynacova.fotografie"} content={"<span style=\"color: rgb(0, 0, 0);\">facebook</span>"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 fs--16" href={"https://www.instagram.com/michaela.synacova.fotografie/"} content={"<span style=\"color: rgb(0, 0, 0);\">instagram</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

              <Text className="text-box fs--18 w--500 mt--0" content={"saywebpage.com"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}